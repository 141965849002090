class IdukkiMainElement extends HTMLElement {
  connectedCallback() {
    // this.applyCommonAttributes();
    // this.addEventListener('click', () => {
    //   this.classList.toggle('clicked');
    // });
  }
  applyCommonAttributes() {
    const classList = this.getAttribute('classname');
    if (classList) {
      this.classList.add(...classList.split(' ').filter((item) => item !== ''));
    }
    const dataInfo = this.getAttribute('data-info');
    if (dataInfo) {
      this.dataset.info = dataInfo;
    }
  }
}

class IdukkiSpan extends HTMLElement {
  connectedCallback() {
    // this.applyCommonAttributes();
    // this.addEventListener('click', () => {
    //   this.classList.toggle('clicked');
    // });
  }
  applyCommonAttributes() {
    const classList = this.getAttribute('classname');
    if (classList) {
      this.classList.add(...classList.split(' ').filter((item) => item !== ''));
    }
    const dataInfo = this.getAttribute('data-info');
    if (dataInfo) {
      this.dataset.info = dataInfo;
    }
  }
}
class IdukkiP extends HTMLElement {
  connectedCallback() {
    // this.applyCommonAttributes();
    // this.addEventListener('click', () => {
    //   this.classList.toggle('clicked');
    // });
  }
  applyCommonAttributes() {
    const classList = this.getAttribute('className');
    if (classList) {
      this.classList.add(...classList.split(' ').filter((item) => item !== ''));
    }
    const dataInfo = this.getAttribute('data-info');
    if (dataInfo) {
      this.dataset.info = dataInfo;
    }
  }
}

if (!customElements.get('idk-div')) {
  customElements.define('idk-div', IdukkiMainElement);
}

if (!customElements.get('idk-p')) {
  customElements.define('idk-p', IdukkiP);
}

if (!customElements.get('idk-span')) {
  customElements.define('idk-span', IdukkiSpan);
}

import React from 'react';
import ReactDOM from 'react-dom/client';
import Router from './Router';
import './index.scss';
//import reportWebVitals from './reportWebVitals';
import EventBus from './common/EventBus';
import './customElements/customElements';

const ugc = document.querySelectorAll('[data-ugc]');

if (ugc.length > 0) {
  for (let ugcElement of ugc) {
    let bid, guid, token, galleryId, pid, pcat, bguid, staging, version, fromRedis;
    if (ugcElement?.getAttribute('data-ugc') === 'idukki') {
      bid = ugcElement?.getAttribute('data-bid');
      guid = ugcElement?.getAttribute('data-guid');
      galleryId = ugcElement?.getAttribute('data-galleryId');
      pid = ugcElement?.getAttribute('filter-pid');
      pcat = ugcElement?.getAttribute('filter-pcat');
      bguid = ugcElement?.getAttribute('data-bguid');
      staging = ugcElement?.getAttribute('data-staging');
      version = ugcElement?.getAttribute('data-version');
      fromRedis = ugcElement?.getAttribute('data-idk-fr');
    }

    const root = ReactDOM.createRoot(ugcElement);
    const dataValues = { bid, guid, token, galleryId, pcat, pid, bguid, version, fromRedis };
    EventBus?.getInstance().fireEvent('isStaging', staging);

    if (!window['IdkScriptLoaded']) {
      root.render(
        <>
          {(() => {
            window['IdkScriptLoaded'] = true;
          })()}
          <Router data={dataValues} />
        </>,
      );
    }
  }
}

import React, { Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

const App = React.lazy(() => import('./App'));
const UploadMedia = React.lazy(() => import('./pages/UploadMedia/UploadMedia.container'));
const Video = React.lazy(() => import('./Video'));

export default function Router({ data }) {
  const urlParams = new URLSearchParams(window.location.search);
  const bguid = urlParams.get('bguid');
  const oguid = urlParams.get('oguid');
  const host = window.location.host;
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/video">
          <Suspense fallback={<></>}>
            <Video />
          </Suspense>
        </Route>
        <Route exact path="/upload">
          <Suspense fallback={<></>}>
            <idk-div class="zid-flex zid-w-full zid-justify-center">
              <UploadMedia bguid={bguid} oguid={oguid} />
            </idk-div>
          </Suspense>
        </Route>
        <Route path="/">
          <Suspense fallback={<></>}>
            {!['widget-staging.idukki.io', 'widget.idukki.io', 'localhost:3001', 'localhost:3000'].includes(host) &&
            !data?.guid ? (
              <idk-div class="zid-flex zid-w-full zid-justify-center">
                <UploadMedia hasDomain={true} bguid={bguid} oguid={oguid} />
              </idk-div>
            ) : (
              <App data={data} />
            )}
          </Suspense>
        </Route>
      </Switch>
    </BrowserRouter>
  );
}
